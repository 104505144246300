import { inject, Injectable, signal, computed } from '@angular/core';
import { ICvxClaimsPrincipal } from '@cvx/cal';
import { AuthenticationResult } from '@azure/msal-common';
import { CalAngularService } from '@cvx/cal-angular';
import { Observable, lastValueFrom } from 'rxjs';

import { AppConfigService } from '../../shared/services/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class CalService {
  private userSignal = signal<ICvxClaimsPrincipal>({} as ICvxClaimsPrincipal);
  private isLoggedInSignal = signal<boolean>(false);

  authService: CalAngularService = inject(CalAngularService);

  isUserLoggedIn = computed(() => this.isLoggedInSignal());
  userInfo = computed(() => this.userSignal());

  firstName = computed(() => {
    const user = this.userSignal();
    if (user.name) {
      return user.name.split(',')[1].split('[')[0].toLocaleLowerCase();
    }
    return '';
  });

  async updateAccessToken(): Promise<string> {
    const graphScopes = AppConfigService.settings.graphScopes.slice(0);
    graphScopes.unshift(
      `${AppConfigService.settings.clientId}/user_impersonation`
    );
    const token: Promise<string> = this.getAADToken(
      graphScopes,
      false
    ) as Promise<string>;

    return token;
  }

  async getAADToken(
    oidcScopes: string[] = AppConfigService.settings.oidcScopes,
    fullAuthResponse: boolean = false
  ): Promise<string | AuthenticationResult | null> {
    return lastValueFrom(this.authService.getAADToken(oidcScopes, fullAuthResponse));
  }

  getToken(
    scopes: string[] = [`${AppConfigService.settings.clientId}/user_impersonation`],
    fullAuthResponse: boolean = false
  ): Observable<string | AuthenticationResult | null> {
    return this.authService.getAADToken(scopes, fullAuthResponse);
  }

  public async signIn(): Promise<boolean> {
    return lastValueFrom(this.authService.userInitiatedSignIn()).then(
      async (data) => {
        this.userSignal.set(data);
        this.isLoggedInSignal.set(data != null);
        await this.updateAccessToken();

        if (!data) {
          console.error('User failed to log in.');
        }

        return data != null;
      }
    );
  }

  public async signOut(): Promise<void> {
    await lastValueFrom(this.authService.userInitiatedSignOut());
    this.userSignal.set({} as ICvxClaimsPrincipal);
    this.isLoggedInSignal.set(false);
  }

}
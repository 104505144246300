import { inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { finalize, Observable, tap } from 'rxjs';
import { AppInsightsService } from '../services/app-insights.service';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {

  appInsightsService = inject(AppInsightsService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const startTime : number = new Date().getTime();
    let _resultCode : number;
    let isSuccessfulResponse : boolean = true;
    let errorResponse: HttpErrorResponse | null = null;

    return next.handle(request).pipe(
      tap({
        // Succeeds when there is a response; ignore other events
        next: (event) => {
          if (event instanceof HttpResponse) {
            if(!event.ok) {
              isSuccessfulResponse = false;
            }
            _resultCode = event.status;
          }
        },
        // Operation failed; error is an HttpErrorResponse
        error: (error : HttpErrorResponse) => {
          isSuccessfulResponse = false;
          _resultCode = error.status;
          errorResponse = error;
        }
      }),
      // Log when response observable either completes or errors
      finalize(() => {
        const endTime = new Date().getTime();

        // can add more attributes to this if needed.
        this.appInsightsService.logEvent('HttpEvent', {
          url: request.url,
          duration: endTime - startTime,
          resultCode: _resultCode,
          success: isSuccessfulResponse,
          method: request.method,
          message: errorResponse?.message // only provide error message in log
        });
      })
    );

  }
}

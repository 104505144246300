import { HttpBackend, HttpClient } from '@angular/common/http';
import { IAppConfig } from '../interfaces/app-config';
import { Injectable } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import { RoleConfig } from '../interfaces/role-config.interface';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Injectable()
export class AppConfigService {
  static settings: IAppConfig;
  static roleConfig: RoleConfig;
  private httpClient: HttpClient;
  constructor(private handler: HttpBackend, private domSanitizer: DomSanitizer) {
    // initialise new HttpClient to avoid global HTTP INTERCEPTOR
    this.httpClient = new HttpClient(handler);
  }

  loadConfig(): Promise<void> {
    const appConfFile = `assets/config.json`;
    return new Promise<void>((resolve, reject) => {
      lastValueFrom(this.httpClient.get<IAppConfig>(appConfFile))
        .then((response) => {
          AppConfigService.settings = response;
          resolve();
        })
        .catch((response: any) => {
          reject(
            `Could not load file '${appConfFile}': ${JSON.stringify(response)}`
          );
        });
    });
  }

  loadRoleConfig(): Promise<void> {
    const roleConfFile = `assets/role-config.json`;
    return new Promise<void>((resolve, reject) => {
      lastValueFrom(this.httpClient.get<RoleConfig>(roleConfFile))
        .then((response) => {
          AppConfigService.roleConfig = response;
          resolve();
        })
        .catch((response: any) => {
          reject(
            `Could not load file '${roleConfFile}': ${JSON.stringify(response)}`
          );
        });
    });
  }

  getIcon(iconName: string): Promise<SafeHtml> {
    const url = `assets/icons/${iconName}.svg`;
    return lastValueFrom(
      this.httpClient.get(url, { responseType: 'text' })
        .pipe(
          map(svgContent => this.domSanitizer.bypassSecurityTrustHtml(svgContent))
        )
    );
  }
}
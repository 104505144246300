<header>
  <ng-content></ng-content>
  <div id="sitename">
    <!-- Logged In User -->
    <span
      id="toggle-search"
      class="icon-user"
      role="button"
      aria-label="Toggle search form"
      aria-pressed="false"
      ><em>welcome<br />{{ firstName }}</em></span
    >

    <!-- TOP MENU TOGGLE -->
    <div
      id="toggle-menu"
      class="topmenu aria-pressed"
      role="button"
      aria-label="Toggle top menu navigation"
      [attr.aria-pressed]="isMenuOpen"
      (click)="toggleMenu()"
      clickOutside
      (clickOutside)="clickedOutside()"
    >
      <span class="icon-menu-hamburger"><em>menu</em></span>
      <span class="icon-remove"></span>
    </div>

    <!-- TOP MENU -->
    <div class="header-nav aria-pressed" [attr.aria-expanded]="isMenuOpen">
      <!-- INSERT MENU NAVIGATION HERE (see below) -->
      <h3><a href="https://chevron.sharepoint.com/:f:/r/sites/RfGLNGDigital/Shared%20Documents/5.0%20Analytical%20Tools/LNG%20Commitments%20and%20Entitlements/Training_UAT/LACEStrainingguide_4users%5BDonotremove%5D" target="_blank">user guide</a></h3>
      <hr class="separator">
      <h3 (click)="onLogout()">logout</h3>
    </div>

    <!-- Site name header -->
    <ng-container *ngIf="!routingEnabled">
      <a id="hallmark" href="/"></a>
      <div>
        <a href="/">{{ siteName }}</a>
      </div>
    </ng-container>
    <ng-container *ngIf="routingEnabled">
      <a id="hallmark" routerLink="/"></a>
      <div>
        <a routerLink="/">{{ siteName }}</a>
      </div>
    </ng-container>
  </div>
</header>

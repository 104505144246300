import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { CalAngularModule } from '@cvx/cal-angular';
import { AppComponent } from './app/app.component';
import { allRoutes } from './app/app.routing';
import { AuthInterceptor } from './app/shared/interceptors/auth.interceptor';
import { LoggingInterceptor } from './app/shared/interceptors/logging.interceptor';
import { AppConfigService } from './app/shared/services/app-config.service';
import { IconRegistryService } from './app/shared/services/icon-registry.service';

export function initializeApp(appConfigService: AppConfigService, iconRegistryService: IconRegistryService) {
  return async (): Promise<void> => {
    await appConfigService.loadConfig();
    await appConfigService.loadRoleConfig();
    await iconRegistryService.loadIcons();
  }
}

export const appConfig : ApplicationConfig = {
  providers: [
    importProvidersFrom(
      [
        BrowserModule,
        //BrowserAnimationsModule,
        CalAngularModule.forRoot('assets/config.json'),
      ]
    ),
    provideAnimations(),
    provideRouter(allRoutes),
    provideHttpClient(
      withInterceptorsFromDi()
    ),
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: LoggingInterceptor,
        multi: true
      },
      AppConfigService,
      IconRegistryService,
      {
        provide: APP_INITIALIZER,
        useFactory: initializeApp,
        deps: [AppConfigService, IconRegistryService],
        multi: true
      },

  ]
}

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
<footer>
    <ul>
        <li *ngIf="lastUpdate !== ''">Last update: {{ lastUpdate }}</li>
        <li>&copy; {{ copyright }} Chevron Corporation</li>
        <li>{{ confidentiality }}</li>
        <li>OpCo: <a href="{{ opcoUrl }}">{{ opcoName }}</a></li>
    </ul>
    <ul>
        <li>Content contact: <a href="{{ contentContactUrl }}">{{ contentContactName }}</a></li>
        <li>Technical contact: <a href="{{ technicalContactUrl }}">{{ technicalContactName }}</a></li>
    </ul>
</footer>
import { Injectable, inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class IconRegistryService {
  private matIconRegistry = inject(MatIconRegistry);
  private appConfigService = inject(AppConfigService);

  async loadIcons(): Promise<void> {
    try {
      const safeHtml = await this.appConfigService.getIcon('excel-icon');
      this.matIconRegistry.addSvgIconLiteral('excel-icon', safeHtml);
    } catch (error) {
      console.error('Error loading Excel icon:', error);
      throw error;
    }
  }
}
<!-- Header -->
<app-header
  siteName="LNG Agreements Commitments and Entitlements System"
  [routingEnabled]="true"
  [firstName]="calService.firstName()"
  (logout)="logout()"
>
</app-header>

<!-- Site-Specific Navigation -->
<app-menu [menuRoutes]="menuRoutes"></app-menu>

<!-- Main Content -->
<main class="container">
  <router-outlet></router-outlet>
</main>

<!-- Footer -->
<app-footer
  [contentContactName]="'LACES Support'"
  [contentContactUrl]="'mailto:laces-support@chevron.com'"
  [technicalContactName]="'LACES Support'"
  [technicalContactUrl]="'mailto:laces-support@chevron.com'"
>
</app-footer>

<nav id="site-specific-nav">
  <ul>
    <ng-container *ngFor="let route of menuRoutes">
      <li>
        <a routerLink="/{{ route.path }}">{{ route.title }}</a>
        <ng-container *ngIf="route.children">
          <ul>
            <ng-container *ngFor="let childRoute of route.children">
              <li>
                <a routerLink="/{{ route.path }}/{{ childRoute.path }}">{{
                  childRoute.title
                }}</a>
              </li>
            </ng-container>
          </ul>
        </ng-container>
      </li>
    </ng-container>
  </ul>
</nav>

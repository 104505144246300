import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { CalService } from './cal.service';
import { inject } from '@angular/core';
import { AzurePolicy } from '../enums/azure-policy.enum';
import { RoleService } from './role.service';

export const RoleGuard: CanActivateFn = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const calService: CalService = inject(CalService);
  const roleService: RoleService = inject(RoleService);
  const router: Router = inject(Router);

  // Wait for the user to be logged in
  if (!calService.isUserLoggedIn()) {
    try {
      await calService.signIn();
    } catch (error) {
      console.error('Failed to sign in:', error);
      return router.createUrlTree(['/login']);
    }
  }
  
  // move to role guard
  const requiredPolicy: AzurePolicy = route.data['requiredPolicy'] as AzurePolicy;
  if (requiredPolicy && !roleService.hasPolicy(requiredPolicy)) {
    console.error(`User does not have the required policy: ${requiredPolicy}`);
    return router.createUrlTree(['/unauthorized']);
  }

  return true;
};

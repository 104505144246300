import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClickOutsideDirective } from '../../directives/clickOutside.directive';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [NgIf, ClickOutsideDirective],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() siteName: string = ""
  @Input() routingEnabled: boolean = true; //this should be changed to true
  @Input() firstName!: string;

  @Output() logout = new EventEmitter<void>();

  isMenuOpen: boolean = false;

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  clickedOutside(): void {
    this.isMenuOpen = false;
  }
  
  onLogout(): void {
    this.logout.emit();
  }
}

import { inject, Injectable } from '@angular/core';
import { CalService } from './cal.service';
import { AzurePolicy } from '../enums/azure-policy.enum';
import { ICvxClaimsPrincipal } from '@cvx/cal';
import { EnvironmentRoles } from '../interfaces/environment-roles.interface';
import { AppConfigService } from '../services/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  // DI
  appConfigService: AppConfigService = inject(AppConfigService);
  private calService: CalService = inject(CalService);

  public hasPolicy(policyName: AzurePolicy): boolean {
    const envRoles: EnvironmentRoles | null = this.getEnvironmentRoles();
    if (!envRoles) return false;

    const userRoles = this.getRoles();
    const policyRoles = this.getPolicyRoles(policyName, envRoles);
    
    return policyRoles ? this.hasAnyRole(userRoles, policyRoles) : false;
  }

  private getEnvironmentRoles(): EnvironmentRoles | null {
    if (!AppConfigService.roleConfig) {
      console.error('Role configuration not loaded');
      return null;
    }

    const environment: string = AppConfigService.settings.environmentName === 'local' 
      ? 'dev' 
      : AppConfigService.settings.environmentName;

    const envRoles: EnvironmentRoles = AppConfigService.roleConfig[environment];

    if (!envRoles) {
      console.error(`No roles defined for environment: ${environment}`);
      return null;
    }

    return envRoles;
  }

  private getPolicyRoles(policyName: AzurePolicy, envRoles: EnvironmentRoles): string[] | null {
    const { AdminRoles, AppAdminRoles, WriterRoles, ReaderRoles } = envRoles;
    
    switch (policyName) {
      case AzurePolicy.ITAdminPolicy:
        return AdminRoles;
      case AzurePolicy.AppAdminPolicy:
        return [...AdminRoles, ...AppAdminRoles];
      case AzurePolicy.WriterPolicy:
        return [...AdminRoles, ...AppAdminRoles, ...WriterRoles];
      case AzurePolicy.ReaderPolicy:
        return [...AdminRoles, ...AppAdminRoles, ...WriterRoles, ...ReaderRoles];
      default:
        console.error(`Unknown policy: ${policyName}`);
        return null;
    }
  }

  private getRoles(): string[] {
    const user: ICvxClaimsPrincipal = this.calService.userInfo();
    return user.roles || [];
  }

  private hasAnyRole(userRoles: string[], requiredRoles: string[]): boolean {
    return userRoles.some((role) => requiredRoles.includes(role));
  }
}

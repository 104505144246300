import { NgIf, NgFor } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [NgIf, NgFor, RouterModule],
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  @Input() menuRoutes!: Route[];
}

import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {
  private appInsights: ApplicationInsights;

  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: AppConfigService.settings.appInsightsConnectionString,
        enableAutoRouteTracking: true
        // Other configuration options
        // https://learn.microsoft.com/en-us/azure/azure-monitor/app/javascript-sdk-configuration#sdk-configuration
      }
    });
    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView(); // Tracks all route changes as page views
  }


  logPageView(name?: string, url?: string) { // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }): void {
    this.appInsights.trackEvent({ name: name, properties: properties });
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message}, properties);
  }

  logAuthUser(email: string ) {
    this.appInsights.setAuthenticatedUserContext(email);
  }

  clearAuthUser() {
    this.appInsights.clearAuthenticatedUserContext();
  }
}

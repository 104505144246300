import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [NgIf],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input() contentContactName = '';
  @Input() contentContactUrl = '';
  @Input() technicalContactName = '';
  @Input() technicalContactUrl = '';

  @Input() copyright = new Date().getFullYear().toString();
  @Input() confidentiality = 'Company Confidential';
  @Input() opcoName = 'GSAT';
  @Input() opcoUrl = 'https://chevron.sharepoint.com/sites/RfGLNGDigital/';
  @Input() lastUpdate = '';
  
}

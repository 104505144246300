import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { CalService } from './cal.service';

export const CalGuard: CanActivateFn = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const calService: CalService = inject(CalService);
  const router: Router = inject(Router);

  // First, check if the user is already logged in

  // Wait for the user to be logged in
  if (!calService.isUserLoggedIn()) {
    try {
      await calService.signIn();
    } catch (error) {
      console.error('Failed to sign in:', error);
      return router.createUrlTree(['/login']);
    }
  }

  let isLoggedIn = calService.isUserLoggedIn();

  if (!isLoggedIn) {
    //return router.createUrlTree(['/login']);
    return false;
  }

  return true;
};
import { Routes } from '@angular/router';

import { CalGuard } from './shared/auth/cal.guard';
import { AzurePolicy } from './shared/enums/azure-policy.enum';
import { RoleGuard } from './shared/auth/role.guard';

// Routes specific to navigation bar
// add here to automatically add to navigation bar
export const menuRoutes: Routes = [
  {
    path: '',
    title: 'attribution reports',
    loadComponent: () =>
      import(
        './features/attribution-report/feature/attribution-report/attribution-report.component'
      ).then((x) => x.AttributionReportComponent),
    canActivate: [CalGuard, RoleGuard],
    data: { requiredPolicy: AzurePolicy.ReaderPolicy }
  },
  {
    path: '',
    title: 'admin',
    children: [
      {
        path: 'legal-entities',
        title: 'legal entities',
        loadComponent: () =>
          import(
            './features/legal-entity/feature/legal-entity-list/legal-entity-list.component'
          ).then((x) => x.LegalEntityListComponent),
        canActivate: [CalGuard, RoleGuard],
        data: { requiredPolicy: AzurePolicy.AppAdminPolicy }
      },
      {
        path: 'agreements',
        title: 'agreements',
        loadComponent: () =>
          import(
            './features/agreement/feature/agreement-list/agreement-list.component'
          ).then((x) => x.AgreementListComponent),
        data: {
          view: 'table',
          pageTitle: 'Agreements',
          backNavigationPath: '/',
          requiredPolicy: AzurePolicy.AppAdminPolicy
        },
        canActivate: [CalGuard, RoleGuard],
      },
      {
        path: 'cell-types',
        title: 'cell type dictionary',
        loadComponent: () =>
          import(
            './features/cell-type-dictionary/feature/cell-type-list/cell-type-list.component'
          ).then((x) => x.CellTypeListComponent),
        canActivate: [CalGuard, RoleGuard],
        data: { requiredPolicy: AzurePolicy.AppAdminPolicy }
      },
      {
        path: 'report-templates',
        title: 'report templates',
        loadComponent: () =>
          import(
            './features//attribution-report-template/feature/attribution-report-template-single/attribution-report-template-single.component'
          ).then((x) => x.AttributionReportTemplateSingleComponent),
        canActivate: [CalGuard, RoleGuard],
        data: { requiredPolicy: AzurePolicy.AppAdminPolicy }
      },
    ],
  },
];

// maybe put in feature folder. e.g. Agreements feature
export const agreementRoutes: Routes = [
  {
    path: 'agreements/new',
    title: 'create agreements',
    loadComponent: () =>
      import(
        './features/agreement/feature/agreement-single/agreement-single.component'
      ).then((x) => x.AgreementSingleComponent),
    canActivate: [CalGuard, RoleGuard],
    data: { isEdit: false, requiredPolicy: AzurePolicy.AppAdminPolicy }
  },
  {
    path: 'agreements/:id',
    title: 'edit agreements',
    loadComponent: () =>
      import(
        './features/agreement/feature/agreement-single/agreement-single.component'
      ).then((x) => x.AgreementSingleComponent),
    canActivate: [CalGuard, RoleGuard],
    data: { isEdit: true, requiredPolicy: AzurePolicy.AppAdminPolicy }
  },
];

// use load children
export const legalEntityRoutes: Routes = [
  {
    path: 'legal-entities/new',
    title: 'create legal entities',
    loadComponent: () =>
      import(
        './features/legal-entity/feature/legal-entity-single/legal-entity-single.component'
      ).then((x) => x.LegalEntitySingleComponent),
    canActivate: [CalGuard, RoleGuard],
    data: { isEdit: false, requiredPolicy: AzurePolicy.AppAdminPolicy }
  },
  {
    path: 'legal-entities/:id',
    title: 'edit legal entities',
    loadComponent: () =>
      import(
        './features/legal-entity/feature/legal-entity-single/legal-entity-single.component'
      ).then((x) => x.LegalEntitySingleComponent),
    canActivate: [CalGuard, RoleGuard],
    data: { isEdit: true, requiredPolicy: AzurePolicy.AppAdminPolicy }
  },
];

export const cellTypeRoutes: Routes = [
  {
    path: 'cell-types/new',
    title: 'new cell type',
    loadComponent: () =>
      import(
        './features/cell-type-dictionary/feature/cell-type-single/cell-type-single.component'
      ).then((x) => x.CellTypeSingleComponent),
    canActivate: [CalGuard, RoleGuard],
    data: { isEdit: false, requiredPolicy: AzurePolicy.ITAdminPolicy }
  },
  {
    path: 'cell-types/:cellType/:id',
    title: 'Edit Cell Type',
    loadComponent: () =>
      import(
        './features/cell-type-dictionary/feature/cell-type-single/cell-type-single.component'
      ).then((x) => x.CellTypeSingleComponent),
    canActivate: [CalGuard, RoleGuard],
    data: { isEdit: true, requiredPolicy: AzurePolicy.ITAdminPolicy }
  },
];

export const unauthorizedRoutes: Routes = [
  {
    path: 'unauthorized',
    title: 'unauthorized',
    loadComponent: () =>
      import(
        './shared/components/unauthorized/unauthorized.component'
      ).then((x) => x.UnauthorizedComponent),
    canActivate: [CalGuard]
  },
]

export const allRoutes: Routes = [
  ...menuRoutes,
  ...agreementRoutes,
  ...legalEntityRoutes,
  ...cellTypeRoutes,
  ...unauthorizedRoutes
];

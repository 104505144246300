import { inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  catchError,
  from,
  map,
  Observable,
  switchMap,
  throwError,
} from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthenticationResult } from '@azure/msal-common';

import { CalAngularService } from '@cvx/cal-angular';

import { AppConfigService } from '../services/app-config.service';

import { ApiMessageDto } from '../models/api-message-dto.model'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}

  authService: CalAngularService = inject(CalAngularService); 

  // to-do add error inceptor for HTTP status codes

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return from(this.authService.getAADToken([AppConfigService.settings.scope])).pipe(
      takeUntilDestroyed(), // use tap to debug if this works
      switchMap((token : string | AuthenticationResult | null) => {
        const authReq = request.clone({
          headers: request.headers
            .append('Access-Control-Allow-Origin', AppConfigService.settings.redirectUri) // grab this from settings file
            .set('Authorization', `Bearer ${token}`)
            .set('Content-Type', 'application/json')
            .set('X-Content-Type-Options', 'nosniff')
            .set('X-XSS-Protection', '1; mode=block'),
          url: `${AppConfigService.settings.apiUrl}/api/${request.url}`,
        });

        // to-do: need to check best practices around this
        return next.handle(authReq).pipe(
          map((res) => {
            return res;
          }),
          catchError((error: HttpErrorResponse) => {
            // Allow 404 errors to pass through
            const whitelistedRequestUrl:string = 'api/template/agreement/';
            const isWhiteListed: boolean = error.url?.includes(whitelistedRequestUrl) ?? false;

            if (error.status === 404 && isWhiteListed) {
              return throwError(() => error);
            }

            // requires a more robust solution - myabe error handling service
            if (error.status === 409) {
              const errorApiResponse: ApiMessageDto = error.error;
              const userErrorMessage: string = 'Someone else has updated the worksheet before you. Please use refresh button to get latest version.'
              return throwError(() => new Error(userErrorMessage));
            }
            
            // Handle other errors
            return throwError(() => new Error(error.message));
          })
        );
      })
    );
  }
}

import { Component, OnInit, DestroyRef, inject } from '@angular/core';
import { Route, RouterOutlet } from '@angular/router';

import { ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { menuRoutes } from './app.routing';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { MenuComponent } from './shared/components/menu/menu.component';
import { AppInsightsService } from './shared/services/app-insights.service';
import { CalService } from './shared/auth/cal.service';
import { RoleService } from './shared/auth/role.service';
import { AzurePolicy } from './shared/enums/azure-policy.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [HeaderComponent, MenuComponent, FooterComponent, RouterOutlet],
  providers: [AppInsightsService],
})
export class AppComponent implements OnInit {
  menuRoutes: Route[] = [];

  calService: CalService = inject(CalService);
  roleService: RoleService = inject(RoleService);
  appInsightsService: AppInsightsService = inject(AppInsightsService);

  ngOnInit(): void {
    this.checkAccount();
  }

  async checkAccount(): Promise<void> {
    if (!this.calService.isUserLoggedIn()) {
      await this.login();
    }

    await this.setupMenuRoutes();
    
    const user: ICvxClaimsPrincipal = this.calService.userInfo();
    this.appInsightsService.logAuthUser(user.email);
    this.appInsightsService.logPageView('LoginPage');
  }

  async login(): Promise<void> {
    const success = await this.calService.signIn();
    if (!success) {
      console.error('Failed to log in');
    }
  }

  async logout(): Promise<void> {
    await this.calService.signOut();
    this.appInsightsService.clearAuthUser();
  }

  private async setupMenuRoutes(): Promise<void> {
    const isAdmin = await this.roleService.hasPolicy(AzurePolicy.ITAdminPolicy) || 
                    await this.roleService.hasPolicy(AzurePolicy.AppAdminPolicy);

    this.menuRoutes = menuRoutes.filter(route => 
      route.title !== 'admin' || isAdmin
    );
  }
}